import React from 'react';


const HeaderBackgroundProduct = () => {
  return (<div className='absolute -z-10 top-0 left-0 -ml-16 productHeroBg'>

    <svg width="100%" height="100%" viewBox="0 0 1342.352 750.003">
      <defs>
        <linearGradient id="linear-gradient" x1="1.235" y1="-0.286" x2="-0.214" y2="0.84" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#3376bd" />
          <stop offset="0.148" stop-color="#3e76bc" />
          <stop offset="0.455" stop-color="#d977af" />
          <stop offset="0.545" stop-color="#ec7ba3" />
          <stop offset="0.636" stop-color="#f98196" />
          <stop offset="0.727" stop-color="#ff8b88" />
          <stop offset="0.818" stop-color="#ff977c" />
          <stop offset="0.909" stop-color="#ffa672" />
          <stop offset="1" stop-color="#ffb56b" />
        </linearGradient>
      </defs>
      <path id="Path_42" data-name="Path 42" d="M962.959,240.088c195.813,9.294,352.973,75.457,467.143,155.922,105.449,74.317,151.323,164.487,114.257,253.836-38.016,91.642-155.317,164.42-309.556,216.937-198.536,67.6-425.373,153.557-648.393,109.235-231.653-46.038-313.687-185.5-355.987-308.841-39-113.727-10.116-234.055,139.826-321.477,143.971-83.939,373.139-116.035,592.71-105.612" transform="translate(-214.704 -238.189)" fill="url(#linear-gradient)" />
    </svg>




  </div>)
}

export default HeaderBackgroundProduct;