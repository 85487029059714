import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Paragraph, Primarybutton, Row } from '../../general';
import Spacer from '../../spacer';
import SetupBlob from './setupBlob';


const SetupStep = (props) => {
    return (<Col className="w-48">
        <div className='relative w-48'>
            <SetupBlob />
            <div className='absolute centerSetupNumber'>
                <p className=' text-8xl z-10'>{props.number}</p>
            </div>
        </div>
        <Paragraph text={props.text} className='font-bold text-center' />
    </Col>);
}


const SetupSection = () => {
    const { t } = useTranslation()
    return (
        <div className='container mx-auto'>
            <Col>
                <Row className="justify-center gap-20 flex-col lg:flex-row">
                    <SetupStep number='1' text={t('setupsection.setup1')} />
                    <SetupStep number='2' text={t('setupsection.setup2')} />
                    <SetupStep number='3' text={t('setupsection.setup3')} />
                    <SetupStep number='4' text={t('setupsection.setup4')} />
                </Row>
                <Spacer />
                <div className='m-auto'>
                    <Primarybutton text={t('setupsection.setupCta')} className='text-center ' />
                </div>
            </Col>

        </div>
    )
}

export default SetupSection