import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, H1, H2, H3, Paragraph, Primarybutton, Row } from '../../general';
import Spacer from '../../spacer';

const InformationSection = (props) => {
    const { t } = useTranslation();

    return (
        <div className='container mx-auto'>
            <Col className='items-center'>
                <H1 text={props.heading} className="text-center w-8/12 primaryColor" />
                <Spacer />
                <Row className="flex-col lg:grid lg:grid-cols-2  gap-7">
                    <Col className='items-start'>
                        <H2 text={props.firstTitle} />
                        <H3 text={props.firstSubtitle} />
                        <Paragraph text={props.firstParagraph} />
                        <Primarybutton text={t('pricing.getStarted')} />
                    </Col>
                    <Col>
                        <img className='w-full' src={props.image} />
                    </Col>
                </Row>
                <Spacer />
                <Row className="flex-col lg:grid lg:grid-cols-2 gap-7">
                    <Col className="lg:order-2 items-start">
                        <H2 text={props.secondTitle} />
                        <H3 text={props.secondSubtitle} />
                        <Paragraph text={props.secondParagraph} />

                        <Primarybutton text={t('pricing.getStarted')} />
                    </Col>
                    <Col className="lg:order-1">
                        <img className='w-full' src={props.secondImage} />
                    </Col>
                </Row>
            </Col>
        </div>
    )
}

export default InformationSection