import React from 'react';

const Mouse = () => {
    return (
        <div>
            <svg width="100%" height="100%" viewBox="0 0 35.47 48.616">
                <defs>
                    <linearGradient id="linear-gradient" x1="1.107" x2="0.159" y2="0.861" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#3376bd" />
                        <stop offset="0.148" stopColor="#3e76bc" />
                        <stop offset="0.455" stopColor="#d977af" />
                        <stop offset="0.545" stopColor="#ec7ba3" />
                        <stop offset="0.636" stopColor="#f98196" />
                        <stop offset="0.727" stopColor="#ff8b88" />
                        <stop offset="0.818" stopColor="#ff977c" />
                        <stop offset="0.909" stopColor="#ffa672" />
                        <stop offset="1" stopColor="#ffb56b" />
                    </linearGradient>
                </defs>
                <path id="Icon_material-mouse" data-name="Icon material-mouse" d="M25.952,1.605v17.58H41.47A17.709,17.709,0,0,0,25.952,1.6ZM6,32.486a17.735,17.735,0,0,0,35.47,0V23.619H6ZM21.518,1.6A17.709,17.709,0,0,0,6,19.185H21.518V1.605Z" transform="translate(-6 -1.605)" fill="url(#linear-gradient)" />
            </svg>

        </div>
    )
}

export default Mouse;