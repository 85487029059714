import React from 'react';

const SetupBlob = () => {
    return (
        <div className='opacityDing'>
            <svg width="100%" height="100%" viewBox="0 0 131.229 145.937">
                <defs>
                    <linearGradient id="linear-gradient" x2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#3376bd" stop-opacity="0.212" />
                        <stop offset="0.148" stop-color="#3e76bc" stop-opacity="0.212" />
                        <stop offset="0.455" stop-color="#d977af" stop-opacity="0.212" />
                        <stop offset="0.545" stop-color="#ec7ba3" stop-opacity="0.212" />
                        <stop offset="0.636" stop-color="#f98196" stop-opacity="0.212" />
                        <stop offset="0.727" stop-color="#ff8b88" stop-opacity="0.212" />
                        <stop offset="0.818" stop-color="#ff977c" stop-opacity="0.212" />
                        <stop offset="0.909" stop-color="#ffa672" stop-opacity="0.212" />
                        <stop offset="1" stop-color="#ffb56b" stop-opacity="0.212" />
                    </linearGradient>
                </defs>
                <path id="Path_47" data-name="Path 47" d="M287.854,238.558c19.143,1.809,34.507,14.683,45.668,30.34,10.309,14.461,14.794,32.006,11.17,49.392-3.716,17.832-15.184,31.993-30.262,42.212-19.409,13.154-41.585,29.879-63.387,21.255-22.647-8.958-30.666-36.094-34.8-60.095-3.813-22.129-.989-45.543,13.669-62.554,14.075-16.333,36.478-22.578,57.944-20.55" transform="translate(-214.704 -238.188)" fill="url(#linear-gradient)" />
            </svg>

        </div>
    )
}

export default SetupBlob